<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
       <div class="box">
          <div>数据识别：数据元与表字段的关联</div>
          <div class="m-t-20">使用模型：数据元关联分析</div>
          <p class="m-t-30">分析结果</p>
          
          <el-table class="m-t-20"  :data="tableData" fit stripe>
            <el-table-column label="序号" type="index" />
            <el-table-column label="数据元" prop="t1" />
            <el-table-column label="字段" prop="t2" />
            <el-table-column label="所属表" prop="t3" />
            <el-table-column label="推荐匹配度" prop="t4" />
          </el-table>
       </div>
    </div>
  </div>

</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb' //面包屑

export default {
  name: 'dataIdentificationResult',
  //注册组件
  components: {
    BreadCrumb,
  },
  data() {
    return {
      tableData: [
        {
        t1: 'xm',
        t2: 'xm',
        t3: 'faren_jcxx',
        t4:'96.16%'
      },
      {
        t1: 'xm',
        t2: 'cbxm',
        t3: 'sbjbxx',
        t4:'91.16%'
      },
      {
        t1: 'xm',
        t2: 'farenxm',
        t3: 'sbjbxx',
        t4:'96.16%'
      },
      {
        t1: 'xm',
        t2: 'cbxm',
        t3: 'wj_csrxx',
        t4:'96.2%'
      },
      {
        t1: 'xm',
        t2: 'cbxm',
        t3: 'sbjbxx',
        t4:'98.16%'
      },
      {
        t1: 'xm',
        t2: 'syrxm',
        t3: 'zxjs_hjxx',
        t4:'96.16%'
      },
      {
        t1: 'xm',
        t2: 'cbxm',
        t3: 'sbjbxx',
        t4:'96.16%'
      },
      {
        t1: 'xm',
        t2: 'cbxm',
        t3: 'sbjbxx',
        t4:'92.11%'
      },
      {
        t1: 'xm',
        t2: 'cbxm',
        t3: 'sbjbxx',
        t4:'96.16%'
      },
      {
        t1: 'xm',
        t2: 'xingming',
        t3: 'zxjs_sbjbxx',
        t4:'96.16%'
      }
  ],
    }
  },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
  },
  methods: {
    //查询
    queryRes() {
      this.projectList()
    },
  },
}
</script>

<style scoped>
  .box{
    padding: 0 5vw;
  }
  .box>div{
    font-size: 16px;
  }
  .box>p{
    font-size: 18px;
  }
</style>
